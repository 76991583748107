import React from "react"

import SEO from "../components/seo"
import Link from "../utils/link"
import { H1 } from "../components/UI/typography"
import { Container, Row, Col } from "react-grid-system"
import { css } from "@emotion/core"

const NotFoundPage = () => (
  <>
    <SEO title="Страница не найдена" />
    <Container>
      <Row justify="center" align="center">
        <Col xs={12} md={6} css={alignment}>
          <img
            src="https://via.placeholder.com/300"
            css={css`
              margin-bottom: 70px;
            `}
            alt=""
          />
          <H1>Страница не найдена</H1>
          <Link to="/">На главную</Link>
        </Col>
      </Row>
    </Container>
  </>
)

const alignment = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default NotFoundPage
